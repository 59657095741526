@import url("https://fonts.googleapis.com/css2?family=Inconsolata&family=Major+Mono+Display&display=swap");

@font-face {
  font-family: 'MaisonNeue';
  src: url('../../fonts/MaisonNeueTRIAL-Thin.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

.title {
  font-family: "MaisonNeue", monospace;
  margin: 0;
  width: auto;
  text-align: center;
  display: block;
  letter-spacing: 0.5rem;
}

.subtitle {
  font-family: "MaisonNeue", monospace;
  margin: 0.1;
  width: auto;
  display: block;
}
.main-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 35%;
  z-index: 4;
}
