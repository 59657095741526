@font-face {
  font-family: "MaisonNeueTHIN";
  src: url("../fonts/MaisonNeueTRIAL-Thin.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "MaisonNeueBOLD";
  src: url("../fonts/Maison\ Neue\ Bold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

.light-side {
  width: 50vw;
  height: 100%;
  background-color: #fff;
  z-index: -1;
}

.dark-side {
  position: absolute;
  width: 50vw;
  height: 100%;
  background-color: rgb(0, 0, 0);
  z-index: -1;
}

.work-container {
  position: absolute;
  width: 20vw;
  height: 35vh;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: transform 0.2s ease-in-out;
  z-index: 5;
  background-color: rgba(252, 252, 252, 0.95);
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
}

.work-container-text {
  pointer-events: none;
  position: absolute;
  width: 20vw;
  height: 35vh;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 6;
  color: rgb(255, 255, 255);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: MaisonNeueBOLD;
  font-size: 30px;
  text-align: center;
}

.data-cursor-tracker-x {
  position: absolute;
  top: 55%;
  left: 50%;
  width: 75vw;
  height: 35vh;
  transform: translate(-50%, -50%);
  z-index: 0;
  cursor: none;
}

.work-container:hover {
  transform: translate(-50%, -50%) scale(1.05);
  cursor: url("../sprites/goToCursor.cur"), auto;
  filter: blur(10px) brightness(70%);
}

#work-container-title-id {
  font-size: 2rem;
  margin-bottom: 2rem;
}
#work-container-desc-id {
  font-family: "MaisonNeueTHIN";
  font-size: 0.8rem;
}

.cursor-wrapper {
  font-family: "MaisonNeueTHIN";
  font-size: 30px;
  visibility: hidden;
  position: absolute;
  z-index: 100;
  pointer-events: none;
  transition: opacity 1s ease-in-out;
  transition: color 0.3s ease-in-out;
  transition: visibility 1s ease-in-out;
  opacity: 0%;
}

.footer {
  position: absolute;
  bottom: -2%;
  width: 110%;
  height: 5vh;
  z-index: 5;
  overflow: hidden;
  display: flex;
  animation: slide-animation 20s linear infinite;
}

.content {
  font-family: "MaisonNeueTHIN";
  position: absolute;
  font-size: 16px;
  right: 0%;
  width: auto;
  cursor: pointer;
  text-decoration: none;
  background-color: rgba(0, 0, 0, 0) !important;
  border-top: rgb(0, 0, 0) 1px solid;
  opacity: 0.5;
}

@keyframes slide-animation {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}
