@font-face {
  font-family: "MaisonNeue";
  src: url("../fonts/MaisonNeueTRIAL-Thin.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

.navBar-light,
.navBar-dark {
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
  height: 3rem;
  z-index: 2;
  font-family: "MaisonNeue", monospace;
  font-size: 1.3rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  z-index: 3;
  transition: background-color 0.9s;
  margin-right: 2rem;
}

.navBar-light {
  background-color: rgba(255, 255, 255, 0.7);
  color: black;
}

.navBar-dark {
  background-color: rgba(38, 38, 38, 0.7);
  color: white;
}

.navHeading {
  margin-right: 1rem;
  text-decoration: none;
  color: inherit;
}

.navButton {
  position: absolute;
  top: 0;
  left: 0;
  margin-left: 1rem;
  margin-top: -0.2rem;
}

.App-light {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  background-color: rgb(255, 255, 255);
  color: rgb(51, 51, 51);
  justify-content: center;
  display: flex;
  transition: background-color 1s;
}

.App-dark {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  background-color: rgb(38, 38, 38);
  color: white;
  justify-content: center;
  display: flex;
  transition: background-color 1s;
}

.canvas-container {
  width: 100%;
  height: 100%;
}

.theme-button {
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  top: 0;
  left: 0;
}

#dark-mode {
  background-image: linear-gradient(to bottom right, #767676, #141414);
  filter: drop-shadow(0 0 0.5rem #6a6a6a);
  display: block;
}

#light-mode {
  background-image: linear-gradient(to bottom right, #c9ba40, #c96908);
  filter: drop-shadow(0 0 0.75rem #f57f7f);
  display: block;
}

.button,
.theme-button {
  transition: transform 0.2s;
  cursor: pointer;
  margin: 0.5rem 0;
  z-index: 2;
}

.button:not(:active),
.button:not(:hover),
.theme-button:not(:active),
.theme-button:not(:hover) {
  transform: scale(1);
  transition: transform 0.2s;
}

.button:hover,
.button:hover .theme-button:hover,
.theme-button:hover {
  transform: scale(0.9);
  /* scale down evenly on all sides on hover */
}

.button:active,
.button:active,
.theme-button:active,
.theme-button:active {
  transform: scale(0.7);
  /* scale down evenly on all sides on click */
}

#github-dark {
  background-image: url("../sprites/git_black.png");
  background-size: 100% 100%;
  width: 40px;
  height: 40px;
}

#linkedin-dark {
  background-image: url("../sprites/linkedin_black.png");
  background-size: 100% 100%;
  width: 32px;
  height: 32px;
}

#twitter-dark {
  background-image: url("../sprites/twitter_black.png");
  background-size: 100% 100%;
  width: 40px;
  height: 40px;
}

#github-light {
  background-image: url("../sprites/git_white.png");
  background-size: 100% 100%;
  width: 40px;
  height: 40px;
}

#linkedin-light {
  background-image: url("../sprites/linkedin_white.png");
  background-size: 100% 100%;
  width: 32px;
  height: 32px;
}

#twitter-light {
  background-image: url("../sprites/twitter_white.png");
  background-size: 100% 100%;
  width: 40px;
  height: 40px;
}

input[type="checkbox"] {
  visibility: hidden;
  display: none;
}
.check {
  position: relative;
  display: block;
  width: 40px;
  height: 20px;
  background-image: rgb(46, 46, 46);
  cursor: pointer;
  border-radius: 20px;
  overflow: hidden;
  transition: ease-in 0.5s;
  box-shadow: 0px 0px 1px 1px rgb(121, 121, 121) inset;
}

input[type="checkbox"]:checked ~ .check {
  background-image: rgb(46, 46, 46);
}

input[type="checkbox"]:checked ~ .check:before {
  background-image: rgb(46, 46, 46);
  transform: translateX(50px);
}

input[type="checkbox"]:checked ~ .check:after {
  background-image: linear-gradient(to bottom right, #c9ba40, #c96908);
  filter: drop-shadow(0 0 0.75rem #f57f7f);
  transform: translateX(0px);
}

.check:before {
  content: "";
  position: absolute;
  top: 2px;
  left: 2px;
  background: rgb(46, 46, 46);
  width: 16px;
  height: 16px;
  border-radius: 50%;
  transition: 0.5s;
}

.check:after {
  content: "";
  position: absolute;
  top: 2px;
  left: 2px;
  background-image: linear-gradient(to bottom right, #c9ba40, #c96908);
  filter: drop-shadow(0 0 0.75rem #f57f7f);
  width: 16px;
  height: 16px;
  border-radius: 50%;
  transition: 0.5s;
  transform: translateX(50px);
}
