@font-face {
  font-family: "MaisonNeueRegular";
  src: url("../fonts/MaisonNeueTRIAL-VAR[BETA].ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

body {
  overflow: hidden;
}

.dark-side {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 50vw;
  z-index: -1;
  background-color: #000;
}
.light-side {
  position: absolute;
  top: 0;
  left: 50%;
  height: 100vh;
  width: 50vw;
  z-index: -1;
  background-color: #fff;
}

.emailInput {
  outline: none;
  border: none;
  font-family: "MaisonNeueRegular";
  width: 15vw;
}

.emailInput.faded {
  opacity: 0;
  transition: 0.5s ease-in-out;
}
.submitInput {
  outline: none;
  border: none;
  background-color: transparent;
}

.flex-wrap {
  display: flex;
}

.info {
  margin-bottom: 1rem;
}

.connect {
  border-bottom: 1px solid #000;
  justify-content: left;
  align-items: center;
  display: inline-block;
}

.submitInput {
  padding: 0;
  margin: 0;
}

.submitInput:hover {
  cursor: pointer;
}

.info-container {
  font-family: "MaisonNeueRegular";
  font-size: 1.1rem;
  color: #000;
  width: 30vw;
  display: inline-block;
  flex-direction: column;
  justify-content: left;
  letter-spacing: 0px;
  top: 20%;
  right: 10%;
  position: absolute;
}

.matthewContainer {
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 50vw;
  transform: scale(30%);
  top: 7.5%;
  left: 0;
  z-index: 5;
}

.firstName {
  position: block;
  width: 50vw;
  margin-bottom: 1rem;
}

.firstName-flipped {
  position: absolute;
  left: 0;
  top: 0.1rem;
  width: 50vw;
  transform-origin: bottom;
  animation: grow 1.5s ease-in-out;
  animation-fill-mode: forwards;
}

.lastName {
  position: absolute;
  bottom: 1%;
  left: 15%;
  animation: moveIn-bottom 2s ease-in-out;
  animation-fill-mode: forwards;
  width: 80vw;
  min-height: 40vh;
  transform: scale(125%);
}

@media screen and (max-width: 600px) {
  .info-container {
    right: 30%;
    width: 60vw;
  }
  .dark-side {
    display: none;
    visibility: hidden;
  }
  .light-side {
    height: 100vh;
    width: 100vw;
  }
  .letter {
    fill: black;
    stroke: black;
  }
}

@keyframes moveIn-fromBottom {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes moveIn-top {
  from {
    top: -1000%;
  }
  to {
    top: 0%;
  }
}

@keyframes moveIn-bottom {
  from {
    left: 200%;
  }
  to {
    left: 14.3%;
  }
}

@keyframes grow {
  from {
    transform: scaleY(0%);
  }
  to {
    transform: scaleY(-100%);
  }
}
