.external-buttons {
    display: flex;
    align-items: center;
    position: relative;
    z-index: inherit;
    position: absolute;
    bottom: 0;
    left: 0;
    flex-direction: column;
    padding: 1rem;
    transform: scale(0.75);
}